import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import KeyboardEventHandler from 'react-keyboard-event-handler'
import { useIndexedDB } from 'react-indexed-db-hook';
import background from './resources/black-bg.jpg'
import { useEventListener } from './hooks';
import { getChatCredentialPromise } from './services/chimeService'
import ButtonReasonDialog from './ButtonReasonDialog';
import DialViewer from './DialViewer';
import ChatPopup from './chime/ChatPopup';
import { cloneDeep } from 'lodash';
import { AspectRatioProvider } from './AspectRatioContext'
import ContentPresenter from './ContentPresenter';
import PreContentPresenter from './PreContentPresenter';
import { Fade, } from '@mui/material';
import ClientOptionsPopup from './ClientOptionsPopup';
import { t } from 'i18next';
import BreakDialog from './BreakDialog';
import { useLocalStorage } from './hooks';


const PREFIX = 'VideoPage';

const classes = {
    root: `${PREFIX}-root`,
    main: `${PREFIX}-main`,
    background: `${PREFIX}-background`,
    logoBox: `${PREFIX}-logoBox`,
    teamLogo: `${PREFIX}-teamLogo`,
    headerLogo: `${PREFIX}-headerLogo`,
    card: `${PREFIX}-card`,
    continue: `${PREFIX}-continue`,
    continueBack: `${PREFIX}-continueBack`,
    appbar: `${PREFIX}-appbar`,
    fixedHeader: `${PREFIX}-fixedHeader`,
    fixedFooter: `${PREFIX}-fixedFooter`,
    white: `${PREFIX}-white`,
    titleBox: `${PREFIX}-titleBox`,
    margin: `${PREFIX}-margin`,
    buttonRow: `${PREFIX}-buttonRow`,
    buttonBox: `${PREFIX}-buttonBox`,
    buttonProgress: `${PREFIX}-buttonProgress`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`
};

const StyledFade = styled(Fade)({
    height: '100%',
    width: '100%',
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
    },
    [`& .${classes.main}`]: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        // background: `url(${background})`,
        // backgroundSize: 'cover',
        // background: 'rgba(21,27,23)',
        background: 'black',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1
    },
    [`& .${classes.background}`]: {
        background: `url(${background})`,
        // background: 'rgba(21,27,23)',
        backgroundSize: 'cover',
        flex: 1
    },
    [`& .${classes.logoBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    [`& .${classes.teamLogo}`]: {
        height: '100%',
        maxHeight: '150px'
    },
    [`& .${classes.headerLogo}`]: {
        height: '100%',
        maxHeight: '80px'
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '400px',
        maxWidth: '800px',
        minHeight: '300px'
    },
    [`& .${classes.continue}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        zIndex: 1002,
    },
    [`& .${classes.continueBack}`]: {
        zIndex: 1001,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(12px)'
    },
    [`& .${classes.appbar}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem 2rem 1rem 2rem',
        boxSizing: 'border-box',
        zIndex: 1000
    },
    [`& .${classes.fixedHeader}`]: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.fixedFooter}`]: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.white}`]: {
        color: 'white'
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.margin}`]: {
        margin: '1rem'
    },
    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    [`& .${classes.buttonBox}`]: {
        position: 'relative'
    },
    [`& .${classes.buttonProgress}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        margin: 'auto',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    },
    // video: {
    //     position: 'absolute',
    //     height: '100vh',
    //     width: '100vw',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     top: 0,
    //     left: 0,
    //     backgroundColor: '#151515'
    // },
});


const short = require('short-uuid');

const noRightClick = (ev) => {
    ev.preventDefault()
    console.log('no right click allowed')
}

const VideoPage = React.memo(({
    token,
    socketConnected,
    encryptedSessionId,
    showPreSurvey,
    surveyRequestId,
    sendMessage,
    videoDataRef,
    techCheck,
    selfGuided,
    encryptedProjectId,
    guestEjected,
    userId,
    email,
    role,
    chimeUsername,
    smsSent,
    smsError,
    resend,
    verified2FA,
    error2FA,
    hasChat,
    sessionStarted,
    sessionEnded,
    advanceIndex,
    fadeOut,
    autoStop,
    videoStopTime,
    sessionId,
    scheduledStartTime,
    started,
    videoTitle,
    team,
    type,
    isLiveSession,
    showCountdown,
    showQuiz,
    chimeChatInstance,
    chimeChatChannels,
    videoPlatform,
    videoId,
    playerId,
    techCheckVideoId,
    multiSection,
    videoIndex,
    currentIndex,
    include2fa,
    override2fa,
    watermarkOpacity,
    watermarkStyle,
    surveyType,
    surveyId,
    videoTime,
    sessionStartTime,
    resetDial,
    viewerTime,
    serverTime,
    videoLength,
    contentLength,
    videoDuration,
    unloadTime,
    isDialSession,
    dialLinkSent,
    dialLinkError,
    connectedDial,
    resendDial,
    dialStyle,
    dialMode,
    gaugeStyle,
    numberOfMeters,
    noDialArrowKeys,
    includeTuneOut,
    includeShoutOuts,
    includeBreaks,
    maxBreaks,
    limitTuneOut,
    startTuneOutTime,
    stopTuneOutTime,
    askTuneOutReason,
    maxReasonLength,
    maxReasonTime,
    scoreBase,
    dialMotion,
    sliderKnob,
    sliderKnobColor,
    inactiveReminder,
    inactiveInterval,
    inactiveMessage,
    allowFullscreen,
    isFullscreen,
    toggleFullScreen,
    onVideoError,
    onVideoComplete,
    disableInteraction,
    zoomMeetingId,
    zoomRegistrantId,
    showChat,
    symbolOptions,
    inputTime,
    feedbackWindowBottomRight
}) => {

    const navigate = useNavigate()

    const [ready, setReady] = useState(type === 'Focus Group')
    const [dialConnected, setDialConnected] = useState(false)
    const [showDialConnection, setShowDialConnection] = useState(!(started && connectedDial))
    const [sendDialData, setSendDialData] = useState(false)
    const [buttonReasonScore, setButtonReasonScore] = useState()
    const [breakDataDialog, setBreakDataDialog] = useState();
    const [isSessionFilesOpen, setIsSessionFilesOpen] = useState(false);
    const [breakCount, setBreakCount] = useLocalStorage(`breakcount${sessionId}-${videoIndex || 0}-${techCheck ? '-TC' : ''}`, 0)


    const { getAll, clear, add } = useIndexedDB('scores');

    // const { isOnline } = useIsOnline(token)

    const codeRef = useRef()
    const unloadRef = useRef(true)
    // const videoTimeRef = useRef(videoTime)
    // const visiblityRef = useRef(document[hidden])
    const dialMessage = useRef()
    // dialMessageRef.current = dialMessage.current
    const hasConnected = useRef()
    hasConnected.current = connectedDial
    // const onlineRef = useRef(true)
    // const showButtonRef = useRef(showButton)
    // const buttonShowTriggered = useRef({})

    const focusGroup = type === 'Focus Group'
    const useDial = isDialSession && role === 'Viewer'
    const rtcDial = useDial && dialMode !== "symbol" && dialStyle !== 'Embedded Slider'
    const notBetween = multiSection && !techCheck ? currentIndex === videoIndex : true
    const showContent = !focusGroup && ((sessionStarted || (techCheck && selfGuided)) && notBetween && ((isLiveSession || techCheck) ? ready : true)) && !((include2fa && !override2fa) && !(verified2FA && verified2FA.isVerified)) && !showPreSurvey && !(rtcDial && (!dialLinkSent || showDialConnection))

    // useEffect(() => {
    //     codeRef.current = authCode
    // }, [authCode])

    // useEffect(() => {
    //     showButtonRef.current = showButton
    // }, [showButton])

    // useEffect(() => {
    //     if (dialLinkSent || dialLinkError || resendDial) {
    //         setWaitingDial(false)
    //     }
    // }, [dialLinkSent, dialLinkError, resendDial])

    useEffect(() => {
        if (showDialConnection && dialConnected) {
            sendMessage(JSON.stringify({
                action: 'status',
                userStatus: 'Not Ready',
                userSubstatus: 'Waiting on Confirm',
                connectedDial: true
            }));
            setTimeout(() => setShowDialConnection(false), 2500)
        }
    }, [dialConnected, showDialConnection, sendMessage])

    // useEffect(() => {
    //     if (smsError || smsSent || resend) {
    //         setWaitingSMS(false)
    //     }
    // }, [smsError, smsSent, resend])

    // useEffect(() => {
    //     if (ratio) setShowDial(true)
    // }, [ratio])

    // useEffect(() => {
    //     if (error2FA || (verified2FA && typeof verified2FA.isVerified === 'boolean')) {
    //         setWaitingVerify(false)
    //     }
    // }, [error2FA, verified2FA])

    useEventListener('contextmenu', noRightClick, false);

    // const updateOnlineStatus = useCallback(() => {
    //     console.log('online?', navigator.onLine)
    //     onlineRef.current = navigator.onLine
    //   }, [])

    // const handleNotWatching = useCallback(() => {
    //     const message = {
    //         action: 'status',
    //         userStatus: 'Not Watching',
    //         notShowing: !document[hidden]
    //     }
    //     console.log('sending not watching message', message)
    //     sendMessage(JSON.stringify(message));
    // }, [sendMessage])

    const handleUnload = useCallback(() => {
        const message = {
            action: 'status',
            userStatus: 'Disconnected',
            userSubstatus: null,
        }
        // if (playerRef.current) {
        //     message.videoTime = (playerRef.current && (playerRef.current.currentTime ? playerRef.current.currentTime() : playerRef.current.getCurrentTime()) || videoTimeRef.current) || 0
        //     message.videoDuration = videoLength // || playerRef.current.duration()
        //     message.unloadTime = Date.now()
        // }
        if (!!videoDataRef?.current) {
            message.videoTime = videoDataRef?.current?.getVideoPosition() || 0
            message.currentIndex = videoIndex
            message.videoDuration = contentLength?.[videoIndex] || videoLength // || playerRef.current.duration()
            message.unloadTime = Date.now()
        }
        console.log('sending unload message', message)
        sendMessage(JSON.stringify(message));
    }, [sendMessage, videoLength, contentLength, videoIndex, videoDataRef])

    useEffect(() => {
        window.addEventListener('beforeunload', handleUnload)
        // window.addEventListener('online', updateOnlineStatus)
        // window.addEventListener('offline', updateOnlineStatus)
        return () => {
            unloadRef.current && window.removeEventListener('beforeunload', handleUnload)
            // window.removeEventListener('online', updateOnlineStatus)
            // window.removeEventListener('offline', updateOnlineStatus)
        }
    }, [handleUnload])

    const handleRedirect = useCallback((nextIndex) => {
        setSendDialData(false)
        if (dialMessage.current) dialMessage.current(JSON.stringify({
            action: 'stopDial'
        }))
        window.removeEventListener('beforeunload', handleUnload)
        unloadRef.current = false
        onVideoComplete(nextIndex)
    }, [handleUnload, onVideoComplete])

    useEffect(() => {
        if (sessionEnded || !!advanceIndex) {
            handleRedirect(!!advanceIndex && videoIndex)
        }
    }, [sessionEnded, advanceIndex, videoIndex, handleRedirect])

    useEffect(() => {
        if (!sessionId) navigate('/error', { state: { error: t('session_not_found'), message: t('video_session_not_found') } })
    }, [sessionId, navigate])

    useEffect(() => {
        if (guestEjected) {
            if (dialMessage.current) dialMessage.current(JSON.stringify({
                action: 'ejectGuest'
            }))
            navigate('/eject', { state: { guestEjected: guestEjected } })
        }
    }, [guestEjected, navigate])

    useEffect(() => {
        console.log('ARE WE ONLINE? ', socketConnected)
        // if (isOnline && socketConnected) {
        if (socketConnected) {
            getAll().then((storedScores) => {
                if (storedScores.length) {
                    sendMessage(JSON.stringify({
                        action: 'dialScore',
                        data: storedScores
                    }));
                    return clear().then(() => {
                        console.log('stored scores cleared!')
                    })
                } else {
                    return Promise.resolve()
                }
            }).then(() => {
                console.log('finished processing indexeddb')
            })
        }
    }, [socketConnected, clear, getAll, sendMessage])

    const handleSendDial = useCallback(() => {
        // setWaitingDial(true)
        sendMessage(JSON.stringify({
            action: 'sendDialLink',
        }));
    }, [sendMessage])

    const handleSendSMS = useCallback(() => {
        // setWaitingSMS(true)
        sendMessage(JSON.stringify({
            action: 'send2FA',
        }));
    }, [sendMessage])

    const handleVerifySMS = useCallback((code) => {
        // setWaitingVerify(true)
        sendMessage(JSON.stringify({
            action: 'verify2FA',
            token: code
        }));
    }, [sendMessage])

    const handleReady = useCallback(() => {
        sendMessage(JSON.stringify({
            action: 'status',
            userStatus: 'Waiting',
            userSubstatus: null,
            currentIndex
        }));
        // if (allowFullscreen && !isFullscreen) toggleFullScreen()
        setReady(true)
    }, [currentIndex, sendMessage])

    const handleVideoStart = useCallback(() => {
        // setSendDialData(true)
        const now = Date.now()
        const videoTime = videoDataRef?.current?.getVideoPosition()
        sendMessage(JSON.stringify({
            action: 'status',
            userStatus: 'Watching',
            userSubstatus: null,
            videoTime,
            viewerTime: now,
            currentIndex: videoIndex
        }));
        setReady(true)
    }, [sendMessage, videoIndex, videoDataRef])

    const handleStartDial = useCallback(() => {
        setSendDialData(true)
    }, [])

    const handlePauseDial = useCallback(() => {
        setSendDialData(false)
    }, [])

    const handleScoreChange = useCallback((scoreData) => {
        if (!techCheck) {
            const videoTime = videoDataRef?.current?.getVideoPosition()
            scoreData.videoTime = videoTime;
            if (multiSection) {
                scoreData.videoIndex = videoIndex
            }
            // if (isOnline && socketConnected) {
            if (socketConnected) {
                console.log('sending score ', scoreData)
                sendMessage(JSON.stringify({
                    action: 'dialScore',
                    data: [scoreData]
                }));
            } else {
                console.log('saving score', scoreData)
                add(scoreData).then((event) => {
                    console.log('saved score', event);
                }).catch((error) => {
                    console.log(error)
                })
            }
            if ((askTuneOutReason && scoreData.button === 'R') || (includeShoutOuts && scoreData.button === 'S') || (includeBreaks && scoreData.button === 'B-S')) {
                setButtonReasonScore(scoreData)
            }
        }
    }, [techCheck, multiSection, videoIndex, videoId, askTuneOutReason, includeShoutOuts, includeBreaks, socketConnected, add, sendMessage, videoDataRef])

    const sendButtonPressReason = useCallback((scoreData) => {
        // if (isOnline && socketConnected) {
        if (socketConnected) {
            console.log('sending score with button press reason', scoreData)
            sendMessage(JSON.stringify({
                action: 'dialScore',
                data: [scoreData]
            }));
        } else {
            console.log('saving score with button press reason', scoreData)
            add(scoreData).then((event) => {
                console.log('saved score with button press reason', event);
            }).catch((error) => {
                console.log(error)
            })
        }
        if (scoreData.button === 'B-S') setBreakDataDialog(scoreData);
        setButtonReasonScore()
    }, [socketConnected, add, sendMessage])

    const sendBreakData = useCallback((data) => {
        if (socketConnected) {
            console.log('sending score with break', data)
            sendMessage(JSON.stringify({
                action: 'dialScore',
                data: [data]
            }));
        } else {
            console.log('saving score with break', data)
            add(data).then((event) => {
                console.log('saved score with break', event);
            }).catch((error) => {
                console.log(error)
            })
        }
        setButtonReasonScore()
    }, [])

    const handleDialConnectionChange = useCallback((connected) => {
        setDialConnected(connected)
    }, [])

    const goToSurvey = useCallback(() => {
        window.removeEventListener('beforeunload', handleUnload)
        unloadRef.current = false
        const requestId = surveyRequestId || short.generate()
        let surveyUrl;
        switch (surveyType) {
            case 'Decipher':
                surveyUrl = `https://survey.everyonesez.com/survey/${surveyId}?list=1&encryptedsessionid=${encryptedSessionId}&id=${userId}&jwt=${token}&prerequestid=${requestId}`
                break;
            case 'Qualtrics':
            default:
                surveyUrl = `https://screenengine.qualtrics.com/jfe/form/${surveyId}?encryptedssessionid=${encryptedSessionId}&id=${userId}&jwt=${token}&prerequestid=${requestId}`
                break;
        }
        sendMessage(JSON.stringify({
            action: 'status',
            userStatus: 'Pre-Survey',
            userSubstatus: null,
            finished: false,
            requestId
        }));
        window.location = surveyUrl
    }, [surveyRequestId, surveyId, encryptedSessionId, userId, sendMessage, handleUnload, surveyType, token])

    // const clearAuthInput = useCallback(() => {
    //     if (codeInputRef.current.textInput[0]) codeInputRef.current.textInput[0].focus()
    //     codeInputRef.current.state.input.forEach((v, i, a) => a[i] = '')
    // }, [])

    // const youtubeLiveStatus = useCallback(async (vid) => {
    //     const status = await getYoutubeLiveStatus(vid, token)
    //     console.log('LIVE VIDEO STATUS', status)
    //     return status
    // }, [token])

    // const videoStreamData = useCallback(async (drmType) => {
    //     const vid = techCheck ? techCheckVideoId : videoId
    //     const status = await getTicktBoxStream(vid, drmType, token)
    //     console.log('GOT TB STREAM DATA', status)
    //     return status
    // }, [techCheck, videoId, techCheckVideoId, token])

    const getChatCredentials = useCallback(async (chatInstance) => {
        return getChatCredentialPromise(chimeUsername, chatInstance, token, sessionId)
    }, [chimeUsername, token, sessionId])

    const allowedChatChannels = useMemo(() => {
        const channels = cloneDeep(chimeChatChannels || [])
        return channels.filter(x => x.userAccess.includes(role)).map(x => (x.userList = !x.userAccess.includes('Viewer'), x))
    }, [chimeChatChannels, role])

    // const onVideoTimeChange = useCallback((event) => {
    //     if (limitTuneOut && (startTuneOutTime || stopTuneOutTime)) {
    //         const time = event.target.player ? event.target.player.currentTime() * 1000 : event.target.currentTime * 1000;
    //         if (showButtonRef.current['R'] && stopTuneOutTime && (videoLength - (stopTuneOutTime * 60000) <= time)) {
    //             setShowButton(prev => ({ ...prev, 'R': false }))
    //         } else if (!(showButtonRef.current['R'] || buttonShowTriggered.current['R']) && startTuneOutTime && (startTuneOutTime * 60000 <= time)) {
    //             buttonShowTriggered.current = { ...buttonShowTriggered.current, 'R': true }
    //             setShowButton(prev => ({ ...prev, 'R': true }))
    //         }
    //     }
    // }, [videoLength, limitTuneOut, startTuneOutTime, stopTuneOutTime]);

    // const VideoPlayer = useMemo(() => {
    //     switch (videoPlatform) {
    //         case 'Brightcove':
    //         default:
    //             return BrightcoveVideoPlayer
    //         case 'YouTube':
    //             return YoutubeVideoPlayer
    //         case 'ticktBox':
    //             return TicktBoxVideoPlayer
    //         // case 'Twitch': 
    //         //     break;
    //     }
    // }, [videoPlatform])


    return (
        <StyledFade in={true}>
            <div className={classes.root}>
                <KeyboardEventHandler
                    // className={classes.root}
                    handleFocusableElements={true}
                    handleKeys={["function", "meta", "ctrl+shift+i"]}
                    onKeyEvent={(key, event) => {
                        event.preventDefault()
                        console.log(key, event)
                    }} />
                <KeyboardEventHandler
                    className={classes.main}
                    // handleFocusableElements={true}
                    handleKeys={["alphabetic", "space", "ctrl", "alt", "ctrl+c"]}
                    onKeyEvent={(key, event) => {
                        event.preventDefault()
                        console.log(key, event)
                    }}>
                    <>
                        {!showContent &&
                            <PreContentPresenter
                                ready={ready}
                                showPreSurvey={showPreSurvey}
                                surveyRequestId={surveyRequestId}
                                techCheck={techCheck}
                                focusGroup={focusGroup}
                                smsSent={smsSent}
                                smsError={smsError}
                                resend={resend}
                                verified2FA={verified2FA}
                                error2FA={error2FA}
                                sessionStarted={sessionStarted}
                                sessionId={sessionId}
                                scheduledStartTime={scheduledStartTime}
                                videoTitle={videoTitle}
                                team={team}
                                isBetween={!notBetween}
                                showCountdown={showCountdown && notBetween}
                                countdownOffset={(viewerTime - serverTime)}
                                showQuiz={!!(showQuiz && role !== 'Client' && notBetween)}
                                include2fa={include2fa}
                                override2fa={override2fa}
                                isResuming={!!unloadTime}
                                rtcDial={rtcDial}
                                dialLinkSent={dialLinkSent}
                                dialLinkError={dialLinkError}
                                resendDial={resendDial}
                                dialConnected={dialConnected}
                                showDialConnection={showDialConnection}
                                goToSurvey={goToSurvey}
                                handleSendDial={handleSendDial}
                                handleSendSMS={handleSendSMS}
                                handleVerifySMS={handleVerifySMS}
                                handleReady={handleReady}
                                hidden={disableInteraction}
                            />
                        }
                        <AspectRatioProvider>
                            {showContent &&
                                <ContentPresenter
                                    token={token}
                                    sessionId={sessionId}
                                    userId={userId}
                                    videoPlatform={videoPlatform}
                                    playerId={playerId}
                                    videoId={techCheck ? techCheckVideoId : videoId}
                                    multiSection={techCheck ? false : multiSection}
                                    videoIndex={videoIndex}
                                    techCheck={techCheck}
                                    fadeOut={fadeOut}
                                    autoStop={autoStop}
                                    videoStopTime={videoStopTime}
                                    wm={email}
                                    wmOpacity={watermarkOpacity}
                                    watermarkStyle={watermarkStyle}
                                    alreadyStarted={!ready}
                                    sessionStartTime={sessionStartTime ? Date.parse(sessionStartTime) + (viewerTime - serverTime) : null}
                                    videoTime={videoTime}
                                    videoDuration={contentLength?.[videoIndex] || videoLength || videoDuration}
                                    unloadTime={unloadTime}
                                    isLive={isLiveSession}
                                    allowFullscreen={allowFullscreen}
                                    isFullscreen={isFullscreen}
                                    toggleFullScreen={toggleFullScreen}
                                    videoDataRef={videoDataRef}
                                    handleVideoStart={handleVideoStart}
                                    handleVideoError={onVideoError}
                                    handleUnload={handleUnload}
                                    handleRedirect={handleRedirect}
                                    // handleNotWatching={handleNotWatching}
                                    hasOnScreenDial={(useDial && dialMode === 'score' && dialStyle === 'Embedded Slider')}
                                    hasEmojiDial={(useDial && dialMode === 'symbol')}
                                    hasOnlyButtons={(useDial && dialMode === 'buttonOnly')}
                                    breakCount={breakCount}
                                    setBreakCount={setBreakCount}
                                    symbolOptions={symbolOptions}
                                    inputTime={inputTime}
                                    maxScore={scoreBase}
                                    dialMotion={dialMotion}
                                    numberOfMeters={numberOfMeters}
                                    sliderKnob={sliderKnob}
                                    sliderKnobColor={sliderKnobColor}
                                    includeTuneOut={includeTuneOut}
                                    includeShoutOuts={includeShoutOuts}
                                    includeBreaks={includeBreaks}
                                    maxBreaks={maxBreaks}
                                    limitTuneOut={limitTuneOut}
                                    startTuneOutTime={startTuneOutTime}
                                    stopTuneOutTime={stopTuneOutTime}
                                    inactiveReminder={inactiveReminder}
                                    inactiveInterval={inactiveInterval}
                                    inactiveMessage={inactiveMessage}
                                    noDialArrowKeys={noDialArrowKeys}
                                    sendData={sendDialData && !buttonReasonScore}
                                    resetDial={resetDial}
                                    handleStartDial={handleStartDial}
                                    handlePauseDial={handlePauseDial}
                                    handleScoreChange={handleScoreChange}
                                />
                                // <div style={{
                                //     position: 'relative',
                                //     flex: '1',
                                //     display: 'flex',
                                //     flexDirection: 'column',
                                //     alignItems: 'center',
                                //     justifyContent: (useDial && dialStyle === 'Embedded Slider') ? 'flex-end' : 'center',

                                // }}>
                                //     <VideoPlayer
                                //         videoRef={playerRef}
                                //         onMetadataLoaded={setVideoAspectRatio}

                                //         // onVisiblityChange={handleNotWatching}
                                //         onTimeUpdate={limitTuneOut ? onVideoTimeChange : undefined}
                                //         
                                //         
                                //         
                                //         
                                //         
                                //         
                                //     />
                                // </div>
                            }
                            {/* {(useDial && dialStyle === 'Embedded Slider') &&
                        <>
                            <EmbeddedSlider
                                                                
                                reset={resetDial}
                                
                            />
                        </>
                    } */}
                            {((rtcDial) && dialLinkSent) &&
                                <DialViewer
                                    token={token}
                                    sendData={sendDialData && !buttonReasonScore}
                                    sendMessageRef={dialMessage}
                                    sessionId={sessionId}
                                    userId={userId}
                                    onConnectionChange={handleDialConnectionChange}
                                    onScoreChange={handleScoreChange}
                                    // show={showDial}
                                    // viewRatio={ratio}
                                    dialStyle={dialStyle}
                                    dialColor={'#323232'}
                                    currentTheme={'light'}
                                    maxAngle={270}
                                    numberOfMeters={numberOfMeters}
                                    useShapes={gaugeStyle === 'With Shapes'}
                                    isOn={true}
                                />
                            }
                        </AspectRatioProvider>
                    </>
                </KeyboardEventHandler>
                {(askTuneOutReason || includeShoutOuts || includeBreaks) && <ButtonReasonDialog feedbackWindowBottomRight={feedbackWindowBottomRight} scoreData={buttonReasonScore} maxLength={maxReasonLength} maxTime={maxReasonTime} onSubmit={sendButtonPressReason} dialMode={dialMode} isDialSession={isDialSession} />}
                {includeBreaks && <BreakDialog feedbackWindowBottomRight={feedbackWindowBottomRight} onSubmit={sendBreakData} setBreakDataDialog={setBreakDataDialog} breakDataDialog={breakDataDialog} breakCount={breakCount} setBreakCount={setBreakCount} videoDataRef={videoDataRef} />}
                {role === "Client" && <ClientOptionsPopup setIsSessionFilesOpen={setIsSessionFilesOpen} isChatPopup={(chimeChatInstance && !!(allowedChatChannels.length || hasChat) || showChat)} encryptedProjectId={encryptedProjectId} zoomMeetingId={zoomMeetingId} zoomRegistrantId={zoomRegistrantId} sessionId={sessionId} token={token} />}
                <ChatPopup displayChat={chimeChatInstance && !!(allowedChatChannels.length || hasChat) || showChat} isSessionFilesOpen={isSessionFilesOpen} openWithChat={showChat} getChatCredentials={getChatCredentials} chatInstance={chimeChatInstance} chatChannels={allowedChatChannels} />
            </div>
        </StyledFade>
    );
})

export default VideoPage;
